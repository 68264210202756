import { getField, updateField } from 'vuex-map-fields';
import AdapultClient from '@/services/http/adapultClient';

const state = () => ({
  bids: [
    {
      id: 0,
      publisherCampaignId: 0,
      min: 0,
      max: 0,
      bidRangeId: '',
      operation: { text: 'Exact', value: 'exact' },
      value: 0,
    },
  ],
});

const getters = {
  getField,
};

const actions = {
  /**
   * getBidRangeList obtains the list of Bid Ranges from the backend and commits
   * the data retrieved and mapped to the desired shape to the bids state. This action
   * should only be used on create. On Edit or View, we should use getBidList.
   *
   * @param {*} state reference to the state of the module
   * @param {*} options query that we will send in the request
   */
  async getDefaultBidRangeList(state, options) {
    try {
      const res = await AdapultClient.getInstance().getBidRanges(options);
      const bids = res.data.items.map((br) => {
        return {
          id: 0,
          publisherCampaignId: 0,
          min: br.min,
          max: br.max,
          bidRangeId: br.id,
          operation: br.defaultOperation,
          value: br.defaultValue,
        };
      });
      state.commit('setBids', bids);
    } catch (error) {
      console.log(error);
    }
  },
  /**
   * getPublisherCampaignBidList obtains the list of Bids Ranges for a given campaign.
   * The data retrieved and mapped to the desired shape to the bids state.
   *
   * @param {*} state reference to the state of the module
   * @param {*} publisherId reference to the publisher id
   * @param {*} publisherCampaignId reference to the publisher campaign id
   * @param {*} options query that we will send in the request
   */

  async getPublisherCampaignBidList(
    state,
    { publisherId, publisherCampaignId }
  ) {
    try {
      const res = await AdapultClient.getInstance().getPublisherCampaignBidList(
        publisherId,
        publisherCampaignId
      );
      const bids = res.data.map((br) => {
        return {
          publisherCampaignId: br.publisherCampaignId,
          bidRangeId: br.bidRangeId,
          min: br.bidRange.min,
          max: br.bidRange.max,
          operation: br.operation,
          value: br.value,
        };
      });

      state.commit('setBids', bids);
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  updateField,
  /**
   * setBids sets(replaces) the state of the bids array. Usually called
   * on initial load by an action.
   *
   * @param {*} state reference to the state of the module
   * @param {*} bids values to set on bids state.
   */
  setBids(state, bids) {
    state.bids = bids;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
