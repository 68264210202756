export default {
  namespaced: true,
  state: {
    isOpen: null,
    groups: [
      {
        title: 'Dashboard',
        active: true,
        items: [{ icon: '', text: 'Dashboard', to: { name: 'dashboard' } }],
      },
      {
        title: 'Organic Business Users',
        items: [
          {
            icon: '',
            text: 'Organic Business Users',
            to: { name: 'businessList' },
          },
          { icon: '', text: 'Listings', to: { name: 'businessListings' } },
          { icon: '', text: 'Feeds', to: { name: 'businessFeeds' } },
        ],
      },
      {
        title: 'Individuals',
        items: [
          { icon: '', text: 'Individuals', to: { name: 'individualsList' } },
          { icon: '', text: 'Email Alerts', to: { name: 'individualsAlerts' } },
          { icon: '', text: 'Listings', to: { name: 'individualsListings' } },
          { icon: '', text: 'Comments', to: { name: 'NotImplemented' } },
        ],
      },
      {
        title: 'Partners',
        items: [
          { icon: '', text: 'Partners', to: { name: 'partnerList' } },
          { icon: '', text: 'Campaigns', to: { name: 'businessCampaigns' } },
        ],
      },
      {
        title: 'Publishers',
        items: [
          { icon: '', text: 'Publishers', to: { name: 'publisherList' } },
          {
            icon: '',
            text: 'Campaigns',
            to: { name: 'publisherCampaignList' },
          },
        ],
      },
      {
        title: 'CBS',
        items: [
          { icon: '', text: '/search', to: { name: 'indexSearch' } },
          { icon: '', text: '/jobs/:id', to: { name: 'cbsJobIndex' } },
        ],
      },
      {
        title: 'Forum',
        items: [
          { icon: '', text: 'Post', to: { name: 'forumPosts' } },
          { icon: '', text: 'Moderators', to: { name: 'NotImplemented' } },
        ],
      },
      {
        title: 'Configuration',
        items: [{ icon: '', text: 'Links', to: { name: 'adminLinksList' } }],
      },
    ],
  },
  mutations: {
    open(state) {
      state.isOpen = true;
    },
    close(state) {
      state.isOpen = false;
    },
    toggle(state) {
      state.isOpen = !state.isOpen;
    },
  },
  getters: {
    isOpen: (state) => state.isOpen,
    groups: (state) => state.groups,
  },
};
