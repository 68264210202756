export default {
  namespaced: true,
  state: {
    active: false,
  },
  mutations: {
    activate(state) {
      state.active = true;
    },
    deactivate(state) {
      state.active = false;
    },
  },
  getters: {
    active: (state) => state.active,
  },
};
