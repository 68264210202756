import Vue from 'vue';
import Vuex from 'vuex';

import VueSecureHTML from 'vue-html-secure';

// Modules
import progressBar from './progressBar';
import navDrawerLeft from './navDrawerLeft';
import snackBar from './snackBar';
import auth from './auth';
import publisherCampaignForm from './modules/publisherCampaignForm/campaign';
import publisherCampaignBidsForm from './modules/publisherCampaignForm/bid';
import publisherCampaignRulesForm from './modules/publisherCampaignForm/rules';

Vue.use(Vuex);
Vue.use(VueSecureHTML);

export default new Vuex.Store({
  modules: {
    progressBar,
    snackBar,
    navDrawerLeft,
    auth,
    publisherCampaignForm,
    publisherCampaignRulesForm,
    publisherCampaignBidsForm,
  },
});
