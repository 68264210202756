export default {
  namespaced: true,
  state: {
    active: false,
    text: '',
    color: 'grey',
  },
  mutations: {
    activateSuccess(state, text = 'Success') {
      state.text = text;
      state.color = 'success';
      state.active = true;
    },
    activateError(state, text = 'Oops, something went wrong') {
      state.text = text;
      state.color = 'error';
      state.active = true;
    },
    activateInfo(state, text = 'Info...') {
      state.text = text;
      state.color = 'info';
      state.active = true;
    },
    activateWarning(state, text = 'Warning...') {
      state.text = text;
      state.color = 'warning';
      state.active = true;
    },
    deactivate(state) {
      state.active = false;
      state.text = '';
      state.color = 'grey';
    },
  },
  getters: {
    active: (state) => state.active,
    text: (state) => state.text,
    color: (state) => state.color,
  },
};
