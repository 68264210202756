<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    class="elevation-0"
    color="white"
    app
  >
    <!-- Nav. Drawer btn activator -->
    <v-app-bar-nav-icon
      class="ml-n3 mr-5"
      color="primary"
      @click.stop="toogleNavDrawerLeft"
    />

    <!-- Get.It Logo -->
    <router-link :to="{ name: 'home' }" class="d-block" style="height: 27px">
      <img :src="getitLogoBlack" alt="Get.It" height="27px" />
    </router-link>

    <v-spacer />

    <!-- User Avatar -->
    <user-dropdown-menu />

    <!-- Bottom Progress Bar -->
    <v-progress-linear
      :active="active"
      color="secondary"
      indeterminate
      absolute
      bottom
    />
  </v-app-bar>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import getitLogoBlack from '@/assets/imgs/getit-logo--black.svg';

// Components
import UserDropdownMenu from '@/components/UserDropdownMenu';

export default {
  name: 'TopBar',
  components: {
    UserDropdownMenu,
  },
  data() {
    return {
      getitLogoBlack,
    };
  },
  computed: {
    ...mapGetters('progressBar', ['active']),
  },
  methods: {
    ...mapMutations('navDrawerLeft', { toogleNavDrawerLeft: 'toggle' }),
  },
};
</script>

<style lang="scss">
.v-toolbar__content {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
