
import { mapGetters } from 'vuex';
import Vue from 'vue';

// Components
import FirstLoadScreen from '@/components/navigation/FirstLoadScreen.vue';
import NavDrawerLeft from '@/components/navigation/NavDrawerLeft.vue';
import TopBar from '@/components/navigation/TopBar.vue';
import SnackBar from '@/components/navigation/SnackBar.vue';

const App = Vue.extend({
  name: 'App',
  components: {
    FirstLoadScreen,
    NavDrawerLeft,
    TopBar,
    SnackBar,
  },
  computed: {
    ...mapGetters('auth', ['loggedIn']),
  },
});

export default App;
