import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { ApiService } from '@/services/api.service';
import { StorageService } from '@/services/storage.service';
import { UserService } from '@/services/user.service';
import router from '@/router';
import store from '@/store';
import VueCompositionAPI from '@vue/composition-api';

import AdapultClient from '@/services/http/adapultClient';
import ReporterClient from '@/services/http/reporterClient';
import BusinessClient from '@/services/http/businessClient';
import PartnerClient from '@/services/http/partnerClient';
import PartnerCampaignClient from '@/services/http/partnerCampaignClient';
import AdminLinkClient from '@/services/http/adminLinkClient';

Vue.use(VueCompositionAPI);

Vue.config.productionTip = false;

// Initializes the ApiService.
ApiService.init(process.env.VUE_APP_BACKEND_BASE_URL);

let adapultClient: AdapultClient;
let reporterClient: ReporterClient;
let businessClient: BusinessClient;
let partnerClient: PartnerClient;
let partnerCampaignClient: PartnerCampaignClient;
let adminLinkClient: AdminLinkClient;

// If there's an acess token, set the Auth Header.
const token = StorageService.getToken();

if (!token) {
  adapultClient = AdapultClient.getInstance(
    process.env.VUE_APP_BACKEND_BASE_URL,
    ''
  );
  reporterClient = ReporterClient.getInstance(
    process.env.VUE_APP_BACKEND_BASE_URL,
    ''
  );
  businessClient = BusinessClient.getInstance(
    process.env.VUE_APP_BACKEND_BASE_URL,
    ''
  );
  partnerClient = PartnerClient.getInstance(
    process.env.VUE_APP_BACKEND_BASE_URL,
    ''
  );
  partnerCampaignClient = PartnerCampaignClient.getInstance(
    process.env.VUE_APP_BACKEND_BASE_URL,
    ''
  );
  adminLinkClient = AdminLinkClient.getInstance(
    process.env.VUE_APP_BACKEND_BASE_URL,
    ''
  );
} else {
  ApiService.setAuthHeader(token);
  partnerClient = PartnerClient.getInstance(
    process.env.VUE_APP_BACKEND_BASE_URL,
    token
  );
  partnerCampaignClient = PartnerCampaignClient.getInstance(
    process.env.VUE_APP_BACKEND_BASE_URL,
    token
  );
  adapultClient = AdapultClient.getInstance(
    process.env.VUE_APP_BACKEND_BASE_URL,
    token
  );
  reporterClient = ReporterClient.getInstance(
    process.env.VUE_APP_BACKEND_BASE_URL,
    token
  );
  businessClient = BusinessClient.getInstance(
    process.env.VUE_APP_BACKEND_BASE_URL,
    token
  );
  adminLinkClient = AdminLinkClient.getInstance(
    process.env.VUE_APP_BACKEND_BASE_URL,
    token
  );
}

// We add the clients to Vue properties so it can be used in all components
Vue.prototype.$adapultClient = adapultClient;
Vue.prototype.$reporterClient = reporterClient;
Vue.prototype.$businessClient = businessClient;
Vue.prototype.$partnerClient = partnerClient;
Vue.prototype.$partnerCampaignClient = partnerCampaignClient;
Vue.prototype.$adminLinkClient = adminLinkClient;

if (process.env.VUE_APP_BACKEND_BASE_URL === 'https://api.management.get.it') {
  Vue.prototype.$cbsUrl = 'https://cbs.get.it';
} else {
  Vue.prototype.$cbsUrl = 'https://cbs.staging.get.it';
}
// Login
UserService.login();

const app = new Vue({
  store: store,
  router: router,
  vuetify: vuetify,
  render: (h) => h(App),
});

app.$mount('#app');
