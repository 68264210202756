export const RULE_TYPE_OPTIONS = [
  { text: 'CPC', value: 1 },
  { text: 'Exclude Title', value: 2 },
  { text: 'Exclude Hiring Organization', value: 3 },
  { text: 'Exclude Feed', value: 4 },
];

export const BID_OPERATION_OPTIONS = [
  { text: 'Exact', value: 'exact' },
  { text: 'Percent', value: 'percent' },
  { text: 'Multiply', value: 'multiply' },
];

export const SOURCE_OPTIONS = [
  {
    text: 'Adapult DB',
    value: 'adapult_db',
  },
  {
    text: 'CBS',
    value: 'cbs',
  },
];

export const CONTENT_TYPE_OPTIONS = [
  {
    text: 'Jobs',
    value: 1,
  },
];
