// import login from './actions/login'
// import getCurrentUser from './actions/getCurrentUser'

const getDefaultState = () => {
  return {
    currentUser: null,
    loadingUser: false,
    loadingUserError: false,
    authenticating: false,
    authenticationError: false,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    setLoginInit(state) {
      state.authenticating = true;
      state.authenticationError = false;
    },
    setLoginSuccess(state) {
      state.authenticating = false;
      state.authenticationError = false;
    },
    setLoginError(state) {
      state.authenticating = false;
      state.authenticationError = true;
    },
    setLoadingUserInit(state) {
      state.loadingUser = true;
      state.loadingUserError = false;
    },
    setLoadingUserSuccess(state) {
      state.loadingUser = false;
      state.loadingUserError = false;
    },
    setLoadingUserError(state) {
      state.loadingUser = false;
      state.loadingUserError = true;
    },
    setCurrentUser(state, obj) {
      // TODO (rafael): We are only setting it to true for now.
      // When we get the payload from the backend, we will save an object.
      state.currentUser = obj;
    },
    setResetAuth(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    // DEACTIVATED, we are touching the login process on `main.js` file.
    // login,
    // getCurrentUser
  },
  getters: {
    loggedIn: (state) => !!state.currentUser,
    currentUser: (state) => state.currentUser,
    loadingUser: (state) => state.loadingUser,
    loadingUserError: (state) => state.loadingUserError,
    authenticating: (state) => state.authenticating,
    authenticationError: (state) => state.authenticationError,
  },
};
