import cleanAxios from 'axios';
const jsonBig = require('json-bigint');
/**
 * Manages REST operations.
 * It's a wrapper on Axios package.
 * It should be initialized before it can be used: `ApiService.init()`.
 **/
const ApiService = {
  // Current axios instance.
  _axios: null,

  /**
   * Initializes the Api Service.
   * @param {String} baseURL - Backend API base URL.
   * @returns {Void}
   */
  init(baseURL) {
    if (!baseURL) {
      throw new Error('ApiService.init: `baseURL` arg is required.');
    }
    if (Object.prototype.toString.call(baseURL) !== '[object String]') {
      throw new TypeError('ApiService.init: `baseURL` should be a string.');
    }
    this._axios = cleanAxios.create({});
    this._axios.defaults.baseURL = baseURL;
    this._axios.defaults.headers.common['Content-Type'] = 'application/json';
    this._axios.defaults.headers.common.Accept = 'application/json';
  },

  /**
   * Sets up the authentication header.
   * @param {String} token - Access token.
   */
  setAuthHeader(token) {
    if (!token) {
      throw new Error('ApiService.setAuthHeader: `token` arg is required.');
    }
    if (Object.prototype.toString.call(token) !== '[object String]') {
      throw new TypeError(
        'ApiService.setAuthHeader: `token` should be a string.'
      );
    }
    this._axios.defaults.headers.common.Authorization = `${token}`;
  },

  /**
   * Clears the authentication header from Axios instance.
   * @returns {Void}
   */
  removeAuthHeaders() {
    if (!this._axios) {
      throw new Error(
        'ApiService.removeAuthHeaders: ApiService was not initialized yet.'
      );
    }
    this._axios.defaults.headers.common.Authorization = null;
  },

  /**
   * Axios GET method.
   * @param {String} url - Resource URL path (without base URL path).
   * @param {Object} [config] - Axios config object.
   * Config doc: https://github.com/axios/axios#request-config
   * @returns {Promise}
   */
  async get(url, config = {}) {
    if (!this._axios) {
      throw new Error('ApiService.get: ApiService was not initialized yet.');
    }
    if (!url) {
      throw new Error('ApiService.get: `url` arg is required.');
    }
    if (Object.prototype.toString.call(url) !== '[object String]') {
      throw new TypeError('ApiService.get: `url` should be a string.');
    }
    return await this._axios.get(url, config);
  },

  /**
   * Axios GET method.
   * @param {String} url - Resource URL path (without base URL path).
   * @param {Object} [config] - Axios config object.
   * Config doc: https://github.com/axios/axios#request-config
   * this uses the JSONbig to parse the response and have access to bigint in javascript
   * @returns {Promise}
   */
  async getBigInt(url, config = {}) {
    if (!this._axios) {
      throw new Error(
        'ApiService.getBigInt: ApiService was not initialized yet.'
      );
    }
    if (!url) {
      throw new Error('ApiService.getBigInt: `url` arg is required.');
    }
    if (Object.prototype.toString.call(url) !== '[object String]') {
      throw new TypeError('ApiService.getBigInt: `url` should be a string.');
    }

    config.transformResponse = function (response) {
      return jsonBig().parse(response);
    };

    return await this._axios.get(url, config);
  },

  /**
   * Axios POST method.
   * @param {String} url - Resource URL path (without base URL path).
   * @param {String} [data] - Data payload.
   * @param {Object} [config] - Axios config object.
   * Config doc: https://github.com/axios/axios#request-config
   * @returns {Promise}
   */
  async post(url, data = {}, config = {}) {
    if (!this._axios) {
      throw new Error('ApiService.post: ApiService was not initialized yet.');
    }
    if (!url) {
      throw new Error('ApiService.post: `url` arg is required.');
    }
    if (Object.prototype.toString.call(url) !== '[object String]') {
      throw new TypeError('ApiService.post: `url` should be a string.');
    }
    return await this._axios.post(url, data, config);
  },

  /**
   * Axios PATCH method.
   * @param {String} url - Resource URL path (without base URL path).
   * @param {String} [data] - Data payload.
   * @param {Object} [config] - Axios config object.
   * Config doc: https://github.com/axios/axios#request-config
   * @returns {Promise}
   */
  async patch(url, data = {}, config = {}) {
    if (!this._axios) {
      throw new Error('ApiService.patch: ApiService was not initialized yet.');
    }
    if (!url) {
      throw new Error('ApiService.patch: `url` arg is required.');
    }
    if (Object.prototype.toString.call(url) !== '[object String]') {
      throw new TypeError('ApiService.patch: `url` should be a string.');
    }
    return await this._axios.patch(url, data, config);
  },

  /**
   * Axios PUT method.
   * @param {String} url - Resource URL path (without base URL path).
   * @param {String} [data] - Data payload.
   * @param {Object} [config] - Axios config object.
   * Config doc: https://github.com/axios/axios#request-config
   * @returns {Promise}
   */
  async put(url, data = {}, config = {}) {
    if (!this._axios) {
      throw new Error('ApiService.put: ApiService was not initialized yet.');
    }
    if (!url) {
      throw new Error('ApiService.put: `url` arg is required.');
    }
    if (Object.prototype.toString.call(url) !== '[object String]') {
      throw new TypeError('ApiService.put: `url` should be a string.');
    }
    return await this._axios.put(url, data, config);
  },

  /**
   * Axios DELETE method.
   * @param {String} url - Resource URL path (without base URL path).
   * @param {Object} [config] - Axios config object.
   * Config doc: https://github.com/axios/axios#request-config
   * @returns {Promise}
   */
  async delete(url, config = {}) {
    if (!this._axios) {
      throw new Error('ApiService.delete: ApiService was not initialized yet.');
    }
    if (!url) {
      throw new Error('ApiService.delete: `url` arg is required.');
    }
    if (Object.prototype.toString.call(url) !== '[object String]') {
      throw new TypeError('ApiService.delete: `url` should be a string.');
    }
    return await this._axios.delete(url, config);
  },
};

export { ApiService };
