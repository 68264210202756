import Cookies from 'js-cookie';
import {
  LOCAL_STRATEGY_ACCESS_TOKEN_COOKIE_NAME,
  GOOGLE_STRATEGY_ACCESS_TOKEN_COOKIE_NAME,
  FACEBOOK_STRATEGY_ACCESS_TOKEN_COOKIE_NAME,
  STRATEGY_COOKIE_NAME,
} from '@/constants/configVariables';

/**
 * Storage Service deals with the browser's cookie storage.
 */
const StorageService = {
  /**
   * Gets the admin's access token from cookie storage.
   * @returns {String|null}
   */
  getToken() {
    let token = null;
    if (
      Cookies.get(LOCAL_STRATEGY_ACCESS_TOKEN_COOKIE_NAME) &&
      Cookies.get(LOCAL_STRATEGY_ACCESS_TOKEN_COOKIE_NAME) !== 'false'
    ) {
      token = Cookies.get(LOCAL_STRATEGY_ACCESS_TOKEN_COOKIE_NAME);
    } else if (
      Cookies.get(GOOGLE_STRATEGY_ACCESS_TOKEN_COOKIE_NAME) &&
      Cookies.get(GOOGLE_STRATEGY_ACCESS_TOKEN_COOKIE_NAME) !== 'false'
    ) {
      token = Cookies.get(GOOGLE_STRATEGY_ACCESS_TOKEN_COOKIE_NAME);
    } else if (
      Cookies.get(FACEBOOK_STRATEGY_ACCESS_TOKEN_COOKIE_NAME) &&
      Cookies.get(FACEBOOK_STRATEGY_ACCESS_TOKEN_COOKIE_NAME) !== 'false'
    ) {
      token = Cookies.get(FACEBOOK_STRATEGY_ACCESS_TOKEN_COOKIE_NAME);
    }
    return token;
  },

  /**
   * Gets the admin's authentication strategy from cookie storage.
   * @returns {String|null}
   */
  getTokenStrategy() {
    return Cookies.get(STRATEGY_COOKIE_NAME);
  },

  /**
   * Set a new cookie in the browser.
   * @param {String} key - Cookie key.
   * @param {String} value - Cookie value.
   * @param {String} [domain] - Cookie domain.
   * @returns {Void}
   */
  setToken(name, value, domain) {
    if (!name) {
      throw new Error('StorageService.setToken: `name` arg is required.');
    }
    if (!value) {
      throw new Error('StorageService.setToken: `value` arg is required.');
    }
    if (Object.prototype.toString.call(name) !== '[object String]') {
      throw new Error('StorageService.setToken: `name` should be a string.');
    }
    if (Object.prototype.toString.call(value) !== '[object String]') {
      throw new Error('StorageService.setToken: `value` should be a string.');
    }
    if (domain && Object.prototype.toString.call(value) !== '[object String]') {
      throw new Error('StorageService.setToken: `domain` should be a string.');
    }

    if (domain) {
      Cookies.set(name, value, { domain });
    } else {
      Cookies.set(name, value);
    }
  },
};

export { StorageService };
