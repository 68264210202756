<template>
  <v-snackbar :value="active" :color="color" top @input="handleInput">
    {{ text }}
    <template #action="{ attrs }">
      <v-btn color="white" text v-bind="attrs" @click="deactivate">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'SnackBar',
  computed: {
    ...mapGetters('snackBar', ['active', 'text', 'color']),
  },
  methods: {
    ...mapMutations('snackBar', ['deactivate']),
    handleInput(value) {
      if (!value) {
        this.deactivate();
      }
    },
  },
};
</script>
