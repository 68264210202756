export const STAGING = 'staging';
export const PRODUCTION = 'production';

// The Get.It frontend application has 3 strategies for authentication:
// - Local (normal signup process);
// - Google (oauth);
// - Facebook (oath).
// The strategy cookie name is configured at the Get.It frontend application.
export const STRATEGY_COOKIE_NAME =
  process.env.NODE_ENV === STAGING ? 'auth_staging.strategy' : 'auth.strategy';

// The following cookie names are configured at the Get.It frontend
// application.
export const LOCAL_STRATEGY_ACCESS_TOKEN_COOKIE_NAME =
  process.env.NODE_ENV === STAGING
    ? 'auth_staging._token.local'
    : 'auth._token.local';
export const GOOGLE_STRATEGY_ACCESS_TOKEN_COOKIE_NAME =
  process.env.NODE_ENV === STAGING
    ? 'auth_staging._token.google'
    : 'auth._token.google';
export const FACEBOOK_STRATEGY_ACCESS_TOKEN_COOKIE_NAME =
  process.env.NODE_ENV === STAGING
    ? 'auth_staging._token.facebook'
    : 'auth._token.facebook';

// Cookie names used to store the admin user access token.
export const MANAGEMENT_FRONTEND_ACCESS_TOKEN_COOKIE_NAME =
  process.env.NODE_ENV === STAGING
    ? 'management_portal_staging.admin_token'
    : 'management_portal.admin_token';
export const MANAGEMENT_FRONTEND_STRATEGY_COOKIE_NAME =
  process.env.NODE_ENV === STAGING
    ? 'management_portal_staging.admin_strategy'
    : 'management_portal.admin_strategy';

// The cookie domain for the cookies being setted on the application.
// These domains are configured at the Get.It frontend application.
export const GETIT_DOMAIN =
  process.env.NODE_ENV === STAGING
    ? 'staging.get.it'
    : process.env.NODE_ENV === PRODUCTION
    ? 'get.it'
    : null;

// Get.It frontend website, based on the environment that this application
// is running.
export const GETIT_FRONTEND_URL =
  process.env.NODE_ENV === STAGING
    ? 'https://staging.get.it'
    : process.env.NODE_ENV === PRODUCTION
    ? 'https://get.it'
    : 'http://localhost:3000';

// Get.It frontend account website, based on the environment that this application
// is running.
export const GETIT_ACCOUNT_URL =
  process.env.NODE_ENV === STAGING
    ? 'https://account.staging.get.it'
    : process.env.NODE_ENV === PRODUCTION
    ? 'https://account.get.it'
    : 'http://localhost:3000';

// Set vars for input options
export const DEFAULT_INTERFACE_TYPES = [
  {
    text: 'API',
    value: 'api',
  },
  {
    text: 'Feed',
    value: 'feed',
  },
];
export const DEFAULT_STATUS = [
  {
    text: 'Active',
    value: 'active',
  },
  {
    text: 'Inactive',
    value: 'inactive',
  },
];
export const DEFAULT_STATUS_PARTNER_CAMPAIGN = [
  {
    text: 'Active',
    value: 'active',
  },
  {
    text: 'Inactive',
    value: 'inactive',
  },
  {
    text: 'Sunset',
    value: 'sunset',
  },
  {
    text: 'Pending',
    value: 'pending',
  },
];
export const DEFAULT_CATEGORY = [
  {
    text: 'Employeer/Staffing',
    value: 'employeer_staffing',
  },
  {
    text: 'Job Board',
    value: 'job_board',
  },
];
