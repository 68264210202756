import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

// Components.

const Partners = () => import('@/views/partners/Partners.vue');
const PartnersList = () => import('@/views/partners/PartnersList.vue');
const PartnersAdd = () => import('@/views/partners/PartnersAdd.vue');
const PartnersEdit = () => import('@/views/partners/PartnersEdit.vue');
const PartnersView = () => import('@/views/partners/PartnersView.vue');

const Business = () => import('@/views/business/Business.vue');
const BusinessList = () => import('@/views/business/BusinessList.vue');
const BusinessEdit = () => import('@/views/business/BusinessEdit.vue');
const BusinessListings = () => import('@/views/business/BusinessListings.vue');
const BusinessFeeds = () => import('@/views/business/BusinessFeeds.vue');
const PartnersCampaigns = () =>
  import('@/views/partners/PartnersCampaigns.vue');
const PartnerCampaign = () => import('@/views/partners/PartnerCampaign.vue');
const PartnersCampaignsView = () =>
  import('@/views/partners/PartnersCampaignsView.vue');
const PartnersCampaignsEdit = () =>
  import('@/views/partners/PartnersCampaignsEdit.vue');
const PartnersCampaignsAdd = () =>
  import('@/views/partners/PartnersCampaignsAdd.vue');

const Individuals = () => import('@/views/user/Individuals.vue');
const IndividualsList = () => import('@/views/user/IndividualsList.vue');
const IndividualsListings = () =>
  import('@/views/user/IndividualsListings.vue');
const IndividualsAlerts = () => import('@/views/user/IndividualsAlerts.vue');

const CBS = () => import('@/views/cbs/CBS.vue');
const CBSSearch = () => import('@/views/cbs/CBSSearch.vue');
const IndexSearch = () =>
  import('@/components/cbs/search/index/IndexSearch.vue');
const StorageSearch = () =>
  import('@/components/cbs/search/storage/StorageSearch.vue');

const CBSJob = () => import('@/views/cbs/CbsJob.vue');
const CBSJobIndex = () => import('@/components/cbs/job/IndexJob.vue');
const CBSJobStorage = () => import('@/components/cbs/job/StorageJob.vue');

const Publisher = () => import('@/views/publisher/Publisher.vue');
const PublisherList = () => import('@/views/publisher/PublisherList.vue');
const PublisherAdd = () => import('@/views/publisher/PublisherAdd.vue');
const PublisherEdit = () => import('@/views/publisher/PublisherEdit.vue');

const AdminLinkEdit = () =>
  import('@/views/configuration/admin/AdminLinkEdit.vue');
const AdminLinkAdd = () =>
  import('@/views/configuration/admin/AdminLinkAdd.vue');
const AdminLinkList = () =>
  import('@/views/configuration/admin/AdminLinkList.vue');
const Admin = () => import('@/views/configuration/admin/Admin.vue');
const Configuration = () => import('@/views/configuration/Configuration.vue');

const PublisherCampaign = () =>
  import('@/views/publisherCampaign/PublisherCampaign.vue');
const PublisherCampaignList = () =>
  import('@/views/publisherCampaign/PublisherCampaignList.vue');
const PublisherCampaignAdd = () =>
  import('@/views/publisherCampaign/PublisherCampaignAdd.vue');
const PublisherCampaignView = () =>
  import('@/views/publisherCampaign/PublisherCampaignView.vue');
const PublisherCampaignEdit = () =>
  import('@/views/publisherCampaign/PublisherCampaignEdit.vue');

const Dashboard = () => import('@/views/dashboard/Dashboard.vue');

const Forum = () => import('@/views/forum/Forum.vue');
const ForumPosts = () => import('@/views/forum/ForumPosts.vue');

const NotImplemented = () => import('@/views/NotImplemented.vue');

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'dashboard' },
    meta: {
      public: false,
      breadCrumb: 'Home',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      public: false,
      breadCrumb: 'Dashboard',
    },
  },
  {
    path: '/business',
    component: Business,
    meta: {
      public: false,
      breadCrumb: 'Organic Business Users',
    },
    children: [
      {
        path: '/business',
        name: 'businessList',
        component: BusinessList,
        meta: {
          public: false,
          breadCrumb: 'List',
        },
      },
      {
        path: 'listings',
        component: BusinessListings,
        name: 'businessListings',
        meta: {
          public: false,
          breadCrumb: 'Listings',
        },
      },
      {
        path: 'feeds',
        component: BusinessFeeds,
        name: 'businessFeeds',
        meta: {
          public: false,
          breadCrumb: 'Feeds',
        },
      },
      {
        path: ':businessID',
        component: Business,
        children: [
          {
            path: 'edit',
            name: 'businessEdit',
            component: BusinessEdit,
            meta: {
              public: false,
              breadCrumb: 'Edit',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/partners',
    component: Partners,
    meta: {
      public: false,
      breadCrumb: 'Partners',
    },
    children: [
      {
        path: '/partners',
        name: 'partnerList',
        component: PartnersList,
        meta: {
          public: false,
          breadCrumb: 'List',
        },
      },
      {
        path: 'campaigns',
        name: 'businessCampaigns',
        component: PartnersCampaigns,
        meta: {
          public: false,
          breadCrumb: 'Campaigns',
        },
      },
      {
        path: 'campaign/add',
        name: 'partnerCampaignAdd',
        component: PartnersCampaignsAdd,
        meta: {
          public: false,
          breadCrumb: 'Campaign',
        },
      },
      {
        path: ':PartnerCampaignID',
        component: PartnerCampaign,
        children: [
          {
            path: 'view',
            name: 'partnerCampaignView',
            component: PartnersCampaignsView,
            meta: {
              public: false,
              breadCrumb: 'View',
            },
          },
          {
            path: 'edit',
            name: 'partnerCampaignEdit',
            component: PartnersCampaignsEdit,
            meta: {
              public: false,
              breadCrumb: 'Edit',
            },
          },
        ],
      },
      {
        path: 'add',
        name: 'partnerAdd',
        component: PartnersAdd,
        meta: {
          public: false,
          breadCrumb: 'Add',
        },
      },
      {
        path: ':PartnerID',
        component: PartnerCampaign,
        children: [
          {
            path: 'edit',
            name: 'partnerEdit',
            component: PartnersEdit,
            meta: {
              public: false,
              breadCrumb: 'Edit',
            },
          },
          {
            path: 'view',
            name: 'partnerView',
            component: PartnersView,
            meta: {
              public: false,
              breadCrumb: 'View',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/individuals',
    component: Individuals,
    meta: {
      public: false,
      breadCrumb: 'Individuals',
    },
    children: [
      {
        path: '/individuals',
        name: 'individualsList',
        component: IndividualsList,
        meta: {
          public: false,
          breadCrumb: 'List',
        },
      },
      {
        path: 'alerts',
        name: 'individualsAlerts',
        component: IndividualsAlerts,
        meta: {
          public: false,
          breadCrumb: 'Alerts',
        },
      },
      {
        path: 'listings',
        name: 'individualsListings',
        component: IndividualsListings,
        meta: {
          public: false,
          breadCrumb: 'Listings',
        },
      },
      {
        path: 'comments',
        name: 'userComments',
        component: NotImplemented,
        meta: {
          public: false,
          breadCrumb: 'Comments',
        },
      },
    ],
  },
  {
    path: '/cbs',
    component: CBS,
    meta: {
      public: false,
      breadCrumb: 'CBS',
    },
    children: [
      {
        path: 'search',
        name: 'cbsSearch',
        component: CBSSearch,
        meta: {
          public: false,
          breadCrumb: 'Search',
        },
        children: [
          {
            path: 'index',
            name: 'indexSearch',
            component: IndexSearch,
            meta: {
              public: false,
              breadCrumb: 'Index',
            },
          },
          {
            path: 'storage',
            name: 'storageSearch',
            component: StorageSearch,
            meta: {
              public: false,
              breadCrumb: 'Storage',
            },
          },
        ],
      },
      {
        path: 'job',
        name: 'cbsJob',
        component: CBSJob,
        meta: {
          public: false,
          breadCrumb: 'Job',
        },
        children: [
          {
            path: 'index/:id?',
            name: 'cbsJobIndex',
            component: CBSJobIndex,
            meta: {
              public: false,
              breadCrumb: 'Index',
            },
          },
          {
            path: 'storage/:id?',
            name: 'cbsJobStorage',
            component: CBSJobStorage,
            meta: {
              public: false,
              breadCrumb: 'Storage',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/forum',
    component: Forum,
    meta: {
      public: false,
      breadCrumb: 'Forum',
    },
    children: [
      {
        path: 'posts',
        name: 'forumPosts',
        component: ForumPosts,
        meta: {
          public: false,
          breadCrumb: 'Forum',
        },
      },
      {
        path: 'moderators',
        name: 'forumModerators',
        component: NotImplemented,
        meta: {
          public: false,
          breadCrumb: 'Moderators',
        },
      },
    ],
  },
  {
    path: '/configuration',
    component: Configuration,
    meta: {
      public: false,
      breadCrumb: 'Configuration',
    },
    children: [
      {
        path: 'links',
        component: Admin,
        meta: {
          public: false,
          breadCrumb: 'Links',
        },
        children: [
          {
            path: '/configuration/links',
            name: 'adminLinksList',
            component: AdminLinkList,
            meta: {
              public: false,
              breadCrumb: 'List',
            },
          },
          {
            path: 'add',
            name: 'adminLinkAdd',
            component: AdminLinkAdd,
            meta: {
              public: false,
              breadCrumb: 'Add',
            },
          },
          {
            path: ':AdminLinkID',
            component: Admin,
            children: [
              {
                path: 'edit',
                name: 'adminLinkEdit',
                component: AdminLinkEdit,
                meta: {
                  public: false,
                  breadCrumb: 'Edit',
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/publishers',
    component: Publisher,
    meta: {
      public: false,
      breadCrumb: 'Publishers',
    },
    children: [
      {
        path: '/publishers',
        name: 'publisherList',
        component: PublisherList,
        meta: {
          public: false,
          breadCrumb: 'List',
        },
      },
      {
        path: 'add',
        name: 'publisherAdd',
        component: PublisherAdd,
        meta: {
          public: false,
          breadCrumb: 'Add',
        },
      },
      {
        path: 'campaigns',
        component: PublisherCampaign,
        meta: {
          public: false,
          breadCrumb: 'Campaigns',
        },
        children: [
          {
            path: '/publishers/campaigns',
            name: 'publisherCampaignList',
            component: PublisherCampaignList,
            meta: {
              public: false,
              breadCrumb: 'Campaigns',
            },
          },
          {
            path: 'add',
            name: 'publisherCampaignAdd',
            component: PublisherCampaignAdd,
            meta: {
              public: false,
              breadCrumb: 'Add',
            },
          },
        ],
      },
      {
        path: ':publisherID',
        component: Publisher,
        children: [
          {
            path: 'edit',
            name: 'publisherEdit',
            component: PublisherEdit,
            meta: {
              public: false,
              breadCrumb: 'Edit',
            },
          },
          {
            path: 'campaigns',
            component: PublisherCampaign,
            meta: {
              public: false,
              breadCrumb: 'Campaigns',
            },
            children: [
              {
                path: ':publisherCampaignID',
                component: PublisherCampaign,
                meta: {
                  public: false,
                },
                children: [
                  {
                    path: 'edit',
                    name: 'publisherCampaignEdit',
                    component: PublisherCampaignEdit,
                    meta: {
                      public: false,
                      breadCrumb: 'Edit',
                    },
                  },
                  {
                    path: '/publishers/:publisherID/campaigns/:publisherCampaignID',
                    name: 'publisherCampaignView',
                    component: PublisherCampaignView,
                    meta: {
                      public: false,
                      breadCrumb: 'View',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/not-implemented',
    name: 'NotImplemented',
    component: NotImplemented,
    meta: {
      public: false,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
