import store from '../store';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';
import {
  LOCAL_STRATEGY_ACCESS_TOKEN_COOKIE_NAME,
  GOOGLE_STRATEGY_ACCESS_TOKEN_COOKIE_NAME,
  FACEBOOK_STRATEGY_ACCESS_TOKEN_COOKIE_NAME,
  GETIT_DOMAIN,
  GETIT_ACCOUNT_URL,
} from '@/constants/configVariables.js';

/**
 * Manages the authentication operations for the user.
 **/
const UserService = {
  /**
   * Tries to fetch from `/dashboard` endpoint. When success, it means
   * the user visiting the management portal is an admin user.
   * @returns {Void}
   */
  async login() {
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      await ApiService.get('/dashboard');
      store.commit('auth/setCurrentUser', true);
    } catch (error) {
      console.error('login error:', { error });
      // Redirect the user to Get.It Frontend website.
      window.location.href = GETIT_ACCOUNT_URL;
    }
  },

  /**
   * Gets the auth payload for another Get.It user.
   * @param {Number} userId - User's ID.
   * @returns {Promise}
   */
  async getAccessTokenAnotherUser(userId) {
    if (!userId) {
      throw new Error(
        'UserService.getAccessTokenAnotherUser: `userId` arg is required.'
      );
    }
    if (isNaN(userId) || !Number.isInteger(userId)) {
      throw new Error(
        'UserService.getAccessTokenAnotherUser: `userId` arg should be an integer.'
      );
    }
    return await ApiService.post(`/api/v1/admin/users/${userId}/login/`);
  },

  /**
   * Logs out the admin user.
   * @returns {Void}
   */
  logout() {
    // Delete/reset cookies.
    // Redirect back to getit.
    StorageService.setToken(
      LOCAL_STRATEGY_ACCESS_TOKEN_COOKIE_NAME,
      'false',
      GETIT_DOMAIN
    );
    StorageService.setToken(
      GOOGLE_STRATEGY_ACCESS_TOKEN_COOKIE_NAME,
      'false',
      GETIT_DOMAIN
    );
    StorageService.setToken(
      FACEBOOK_STRATEGY_ACCESS_TOKEN_COOKIE_NAME,
      'false',
      GETIT_DOMAIN
    );
    window.location.href = GETIT_ACCOUNT_URL;
  },
};

export { UserService };
