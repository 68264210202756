<template>
  <div class="c-FirstLoadScreen">
    <img
      :src="getitLogo"
      alt="Get.It"
      height="24px"
      class="mb-4"
      style="margin-top: 1px"
    />

    <!-- Spinner: loading -->
    <v-progress-circular
      :size="20"
      class="d-block"
      color="secondary"
      indeterminate
    />
  </div>
</template>

<script>
import getitLogo from '@/assets/imgs/getit-logo--black.svg';

export default {
  data() {
    return {
      getitLogo,
    };
  },
};
</script>

<style lang="scss">
.c-FirstLoadScreen {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
