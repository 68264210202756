import axios, { AxiosInstance, AxiosResponse } from 'axios';

/**
 * HttpClient defines a generic class for specific services http clients to extend from.
 */
export abstract class HttpClient {
  protected readonly instance: AxiosInstance;
  /**
   *
   * @param baseURL Target url for the client
   */
  public constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
      headers: { 'Content-Type': 'application/json' },
    });
    // add interceptor
    this._initializeResponseInterceptor();
    // camelcase
  }

  private _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this._handleResponse,
      this._handleError
    );
  };

  private _handleResponse = (data: AxiosResponse) => data;

  protected _handleError = (error: any) => Promise.reject(error);
}
