import { getField, updateField } from 'vuex-map-fields';
import { RULE_TYPE_OPTIONS } from '@/constants/forms';

const state = () => ({
  rules: [
    {
      id: '',
      ruleTypeId: 0,
      value: '',
    },
  ],
  ruleTypes: [
    {
      text: '',
      value: '',
    },
  ],
});

const getters = {
  getField,
};

const actions = {
  /**
   * updateRules will update the rules state based on the passed
   * rules parameter
   *
   * @param {*} state reference to the state of the module
   * @param {*} rules rules that we want to commit to the rules state
   */
  updateRules(state, rules) {
    state.commit('setRules', rules);
  },
  /**
   * addRule adds a new rule to the rules state. Usually used to
   * create a new empty element.
   *
   * @param {*} state reference to the state of the module
   * @param {*} rule
   */
  addRule(state, rule) {
    state.commit('addRule', rule);
  },

  /**
   * setRulesEmpty empties the state
   *
   * @param {*} state reference to the state of the module
   * @param {*} rules the index of a rule in the array
   */
  setRulesEmpty(state) {
    state.commit('setRules', [
      {
        id: '',
        ruleTypeId: 0,
        value: '',
      },
    ]);
  },

  /**
   * deleteRule delete a rule to the rules state.
   *
   * @param {*} state reference to the state of the module
   * @param {*} index the index of a rule in the array
   */
  deleteRule(state, index) {
    var arr = state.state.rules;
    arr.splice(index, 1);
  },
  /**
   * getRuleTypes sets the default rule types options. In the future
   * this will be updated to get from the backend
   *
   * @param {*} state reference to the state of the module
   */
  getRuleTypes(state) {
    const types = RULE_TYPE_OPTIONS;
    state.commit('setRuleTypes', types);
  },
};

const mutations = {
  updateField,
  setRules(state, rules) {
    state.rules = rules;
  },
  addRule(state, rule) {
    state.rules.push(rule);
  },
  setRuleTypes(state, types) {
    state.ruleTypes = types;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
