
import { mapGetters, mapMutations } from 'vuex';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  computed: {
    ...mapGetters('navDrawerLeft', ['isOpen', 'groups']),
  },
  methods: {
    ...mapMutations('navDrawerLeft', ['open', 'close']),
  },
})
export default class NavDrawerLeft extends Vue {
  /**
   * handleInput is used to toggle the navigation drawer when the user
   * is in mobile device. The state is stored on vuex.
   */
  handleInput(value: boolean) {
    value ? this.open() : this.close();
  }

  open!: () => void;
  close!: () => void;
}
