import { HttpClient } from './httpclient';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import { AxiosRequestConfig } from 'axios';
import {
  PartnerReport,
  PartnerReportResponse,
  PartnerReportResponseList,
  PartnerFTP,
  PartnerFTPResponse,
} from '@/models/reporter';

/**
 * ReporterClient implements the methods to communicate with reporter Service through
 * Management Backend service.
 */
export default class ReporterClient extends HttpClient {
  private static classInstance?: ReporterClient;

  basePath = '/api/v1/reporter';
  token = '';
  /**
   *
   * @param baseURL Base url for the client. Defaults to VUE_APP_BACKEND_BASE_URL env var.
   * @param token Authorization token to talk to adapult
   */
  private constructor(
    baseURL = process.env.VUE_APP_BACKEND_BASE_URL,
    token: string
  ) {
    super(baseURL);
    this.token = token;
    if (this.token != '') {
      this._initializeRequestInterceptor();
    }

    this._transformPayload();
  }

  /**
   * @param baseURL Base url for the client. Defaults to VUE_APP_BACKEND_BASE_URL env var.
   * @param token Authorization token to talk to adapult
   * @returns
   */
  public static getInstance(
    baseURL = process.env.VUE_APP_BACKEND_BASE_URL,
    token: string
  ) {
    if (!this.classInstance) {
      this.classInstance = new ReporterClient(baseURL, token);
    }
    return this.classInstance;
  }

  /**
   * Make all requests transform from:
   * snake_case response payload -> camelCase transformation
   * camelCase request payload -> snake_case transformation
   */
  private _transformPayload = () => {
    this.instance.defaults.transformResponse = [
      (data, headers) => {
        if (data && headers) {
          if (
            headers['content-type'].includes('application/json') ||
            headers['Content-Type'].includes('application/json')
          ) {
            return camelcaseKeys(JSON.parse(data), { deep: true });
          }
        }
      },
    ];

    this.instance.defaults.transformRequest = [
      (data, headers) => {
        if (data && headers) {
          if (headers['Content-Type'].includes('application/json')) {
            headers['Content-Type'] = 'application/json';
            return JSON.stringify(snakecaseKeys(data, { deep: true }));
          }
        }
      },
    ];
  };

  /**
   * Makes all requests add the authorization token in the request IF the object
   * was constructed with a non empty token, otherwise ignored.
   */
  private _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(
      this._handleRequest,
      this._handleError
    );
  };

  private _handleRequest = (config: AxiosRequestConfig) => {
    if (config.headers) {
      config.headers['Authorization'] = this.token;
    }

    return config;
  };

  /*=============================================================================*/
  /*==PartnerReport==============================================================*/
  /*=============================================================================*/

  /**
   * Makes a call to the backend to create a partner
   * @param id The id of the partner to create
   * @param payload data to send in request's body
   * @returns Promise to be handled
   */
  public createPartnerReport = (id: number, payload: PartnerReport) =>
    this.instance.post<PartnerReportResponse>(
      `${this.basePath}/partner/${id}/report`,
      payload
    );

  /**
   * Makes a call to the backend to retrieve a list of partners by partnerID as id
   * @param params
   * @returns Promise to be http response
   */
  public getPartnerReportList = (id: number, params: URLSearchParams) =>
    this.instance.get<PartnerReportResponseList>(
      `${this.basePath}/partner/${id}/report`,
      { params: params }
    );

  /**
   * Makes a call to the backend to edit a partner report
   * @param id The id of the partner report to create
   * @param params data to send in request's body
   * @returns Promise to be handled
   */
  public editPartnerReport = (id: number, params: PartnerReport) =>
    this.instance.put<PartnerReportResponse>(
      `${this.basePath}/partner/${id}/report`,
      params
    );

  /**
   * Makes a call to the backend to delete a partner report
   * @param id The id of the partner report to delete
   * @returns Promise to be handled
   */
  public deletePartnerReport = (id: number) =>
    this.instance.delete(`${this.basePath}/partner/${id}/report`);

  /*=============================================================================*/
  /*==PartnerFTP=================================================================*/
  /*=============================================================================*/

  /**
   * Makes a call to the backend to retrieve a partner ftp by the partner id
   * By partner Id
   * @param id The id of the partner id
   * @returns Promise to be http response
   */
  public getPartnerFTP = (id: number) =>
    this.instance.get<PartnerFTPResponse>(`${this.basePath}/partner/${id}/ftp`);

  /**
   * Makes a call to the backend to edit a partner ftp
   * @param id The id of the partner ftp to create
   * @param params data to send in request's body
   * @returns Promise to be handled
   */
  public editPartnerFTP = (id: number, params: PartnerFTP) =>
    this.instance.put<PartnerFTPResponse>(
      `${this.basePath}/partner/${id}/ftp`,
      params
    );

  /**
   * Makes a call to the backend to create a partner FTP
   * @param id The id of the partner to create
   * @param payload data to send in request's body
   * @returns Promise to be handled
   */
  public createPartnerFTP = (id: number, payload: PartnerFTP) =>
    this.instance.post<PartnerFTPResponse>(
      `${this.basePath}/partner/${id}/ftp`,
      payload
    );
}
