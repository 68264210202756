import { HttpClient } from './httpclient';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import { AxiosRequestConfig } from 'axios';
import {
  AdminLink,
  AdminLinkResponse,
  AdminLinkResponseList,
  AdminLinkCreateRequest,
} from '@/models/adminLink';

/**
 * AdminLinkClient implements the methods to communicate with AdminLink Service through
 * Management Backend service.
 */
export default class AdminLinkClient extends HttpClient {
  private static classInstance?: AdminLinkClient;

  basePath = '/api/v1/admin/link';
  token = '';

  /**
   *
   * @param baseURL Base url for the client. Defaults to VUE_APP_BACKEND_BASE_URL env var.
   * @param token Authorization token to talk to adapult
   */
  private constructor(
    baseURL = process.env.VUE_APP_BACKEND_BASE_URL,
    token: string
  ) {
    super(baseURL);
    this.token = token;
    if (this.token != '') {
      this._initializeRequestInterceptor();
    }

    this._transformPayload();
  }

  /**
   *
   * @param baseURL Base url for the client. Defaults to VUE_APP_BACKEND_BASE_URL env var.
   * @param token Authorization token to talk to adapult
   * @returns
   */
  public static getInstance(
    baseURL = process.env.VUE_APP_BACKEND_BASE_URL,
    token: string
  ) {
    if (!this.classInstance) {
      this.classInstance = new AdminLinkClient(baseURL, token);
    }
    return this.classInstance;
  }

  /**
   * Make all requests transform from:
   * snake_case response payload -> camelCase transformation
   * camelCase request payload -> snake_case transformation
   */
  private _transformPayload = () => {
    this.instance.defaults.transformResponse = [
      (data, headers) => {
        if (data && headers) {
          if (
            headers['content-type']?.includes('application/json') ||
            headers['Content-Type']?.includes('application/json')
          ) {
            return camelcaseKeys(JSON.parse(data), { deep: true });
          }
        }
      },
    ];

    this.instance.defaults.transformRequest = [
      (data, headers) => {
        if (data && headers) {
          if (
            headers['Content-Type']?.includes('application/json') ||
            headers['content-type']?.includes('application/json')
          ) {
            headers['Content-Type'] = 'application/json';
            return JSON.stringify(snakecaseKeys(data, { deep: true }));
          }
        }
      },
    ];
  };

  /**
   * Makes all requests add the authorization token in the request IF the object
   * was constructed with a non-empty token, otherwise ignored.
   */
  private _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(
      this._handleRequest,
      this._handleError
    );
  };

  private _handleRequest = (config: AxiosRequestConfig) => {
    if (config.headers) {
      config.headers['Authorization'] = this.token;
    }

    return config;
  };

  /*=============================================================================*/
  /*==AdminLink==================================================================*/
  /*=============================================================================*/

  /**
   * Makes a call to the backend to retrieve an AdminLink list
   * @param id
   * @returns Promise to be http response
   */
  public getAdminLinks = (params: URLSearchParams) =>
    this.instance.get<AdminLinkResponseList>(`${this.basePath}/`, {
      params: params,
    });

  /**
   * Makes a call to the backend to retrieve an AdminLink by its id
   * @param id
   * @returns Promise to be http response
   */
  public getAdminLinkById = (id: number) =>
    this.instance.get<AdminLinkResponseList>(`${this.basePath}/${id}/`);

  /**
   * Makes a call to the backend to edit an AdminLink
   * @param id
   * @param payload data to send in request's body
   * @returns Promise to be handled
   */
  public editAdminLink = (id: number, payload: AdminLink) =>
    this.instance.put<AdminLinkResponse>(`${this.basePath}/${id}/`, payload);

  /**
   * Makes a call to the backend to create an AdminLink
   * @param id
   * @param payload data to send in request's body
   * @returns Promise to be handled
   */
  public createAdminLink = (payload: AdminLinkCreateRequest) =>
    this.instance.post<AdminLinkResponse>(`${this.basePath}/`, payload);

  /**
   * Makes a call to the backend to delete an AdminLink
   * @param id
   * @returns Promise to be handled
   */
  public deleteAdminLink = (id: number) =>
    this.instance.delete(`${this.basePath}/${id}/`);
}
