<template>
  <div class="c-UserDropdownMenu">
    <!--
      VMenu component wraps the avatar.
    -->
    <v-menu :nudge-bottom="5" offset-y left>
      <!-- Activator: Avatar -->
      <template #activator="{ on }">
        <v-avatar size="32" style="cursor: pointer" v-on="on">
          <v-icon color="primary" size="32"> mdi-account-circle </v-icon>
        </v-avatar>
      </template>

      <!-- Content -->
      <div class="c-UserDropdownMenu__content">
        <!-- Management -->
        <v-list>
          <v-list-item to="/" class="v-list-item--active">
            <v-list-item-action>
              <v-icon>mdi-chart-box-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content> Management </v-list-item-content>
          </v-list-item>

          <!-- Dashboard -->
          <v-list-item :href="linkToGetitUserCallbackPage">
            <v-list-item-action>
              <v-icon>mdi-monitor-dashboard</v-icon>
            </v-list-item-action>
            <v-list-item-content> Dashboard </v-list-item-content>
          </v-list-item>
        </v-list>

        <!-- Divider -->
        <v-divider />

        <!-- Sign Out -->
        <v-list>
          <!-- Sign Out -->
          <v-list-item @click="handleClickLogout">
            <v-list-item-action>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title> Log out </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-menu>
  </div>
</template>

<script>
import { UserService } from '@/services/user.service';
import { GETIT_ACCOUNT_URL } from '@/constants/configVariables.js';

export default {
  name: 'UserDropdownMenu',
  computed: {
    /**
     * Computed property that returns the URL to Get.It > User callback page.
     * The user callack page is a page that redirects a logged in user
     * to the correct dashboard page (there are 2 types of users, businesses
     * and individuals).
     * @returns {String}
     */
    linkToGetitUserCallbackPage() {
      return `${GETIT_ACCOUNT_URL}/users/callback`;
    },
  },
  methods: {
    /**
     * Handles the logout action.
     * @returns {Void}
     */
    handleClickLogout() {
      UserService.logout();
    },
  },
};
</script>

<style lang="scss">
.c-UserDropdownMenu {
  &__content {
    width: 12.5rem;
    background-color: white;
  }
}
</style>
