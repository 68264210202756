import {
  Publisher,
  PublisherCreateRequest,
  PublishersResponse,
} from '@/models/publisher';
import {
  PublisherCampaignsResponse,
  PublisherCampaignCreateRequest,
  PublisherCampaign,
} from '@/models/publisherCampaign';
import { PublisherCampaignBid } from '@/models/publisherCampaignBid';
import { BidRangesResponse } from '@/models/bidRange';
import { FeedFormatResponse } from '@/models/feedFormat';
import { HttpClient } from './httpclient';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import { AxiosRequestConfig } from 'axios';
import { StandardCreateResponse } from '@/models/adapult';
import {
  parse as jsonBigIntParse,
  stringify as jsonBigIntStringify,
} from 'json-bigint';

/**
 * AdapultClient implements the methods to communicate with Adapult Service through
 * Management Backend service.
 */
export default class AdapultClient extends HttpClient {
  private static classInstance?: AdapultClient;

  basePath = '/api/v1/adapult';
  token = '';
  /**
   *
   * @param baseURL Base url for the client. Defaults to VUE_APP_BACKEND_BASE_URL env var.
   * @param token Authorization token to talk to adapult
   */
  private constructor(
    baseURL = process.env.VUE_APP_BACKEND_BASE_URL,
    token: string
  ) {
    super(baseURL);
    this.token = token;
    if (this.token != '') {
      this._initializeRequestInterceptor();
    }

    this._transformPayload();
  }

  /**
   *
   * @param baseURL Base url for the client. Defaults to VUE_APP_BACKEND_BASE_URL env var.
   * @param token Authorization token to talk to adapult
   * @returns
   */
  public static getInstance(
    baseURL = process.env.VUE_APP_BACKEND_BASE_URL,
    token: string
  ) {
    if (!this.classInstance) {
      this.classInstance = new AdapultClient(baseURL, token);
    }
    return this.classInstance;
  }

  /**
   * Make all requests transform from:
   * snake_case response payload -> camelCase transformation
   * camelCase request payload -> snake_case transformation
   */
  private _transformPayload = () => {
    this.instance.defaults.transformResponse = [
      (data, headers) => {
        if (data && headers) {
          if (
            headers['content-type'].includes('application/json') ||
            headers['Content-Type'].includes('application/json')
          ) {
            return camelcaseKeys(jsonBigIntParse(data), { deep: true });
          }
        }
      },
    ];

    this.instance.defaults.transformRequest = [
      (data, headers) => {
        if (data && headers) {
          if (headers['Content-Type'].includes('application/json')) {
            headers['Content-Type'] = 'application/json';
            return jsonBigIntStringify(snakecaseKeys(data, { deep: true }));
          }
        }
      },
    ];
  };

  /**
   * Makes all requests add the authorization token in the request IF the object
   * was constructed with a non empty token, otherwise ignored.
   */
  private _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(
      this._handleRequest,
      this._handleError
    );
  };

  private _handleRequest = (config: AxiosRequestConfig) => {
    if (config.headers) {
      config.headers['Authorization'] = this.token;
    }

    return config;
  };

  /**
   * Makes a call to the backend to retrieve a list of publishers
   * @param params
   * @returns Promise to be http response
   */
  public getPublishers = (params: URLSearchParams) =>
    this.instance.get<PublishersResponse>(`${this.basePath}/publisher`, {
      params: params,
    });

  // postPublishers will recive data from parameters and create a new publisher
  public postPublishers = (payload: PublisherCreateRequest) =>
    this.instance.post<PublishersResponse>(
      `${this.basePath}/publisher`,
      payload
    );

  // editPublishers will recive data from parameters and edit a publisher
  public editPublishers = (id: number, params: PublisherCreateRequest) =>
    this.instance.put<PublishersResponse>(
      `${this.basePath}/publisher/${id}`,
      params
    );

  // getPublisherById will recive data from parameters and get a publisher
  public getPublisherById = (id: number) =>
    this.instance.get<Publisher>(`${this.basePath}/publisher/${id}`);

  /**
   * Makes a call to the backend to delete a publisher
   * @param id The id of the publisher to delete
   * @returns Promise to be handled
   */
  public deletePublisher = (id: number) =>
    this.instance.delete(`${this.basePath}/publisher/${id}`);

  /* ------------- */

  // getPublisherCampaigns will return a list of publisher campangs
  public getPublisherCampaigns = (params: URLSearchParams) =>
    this.instance.get<PublisherCampaignsResponse>(
      `${this.basePath}/publishers/campaign`,
      { params: params }
    );

  /**
   * createPublisherCampaigns requests the creation of a Publisher Campaign Resource
   *
   * @param payload data to send in request's body
   * @returns promise with a http response
   */
  public postPublisherCampaign = (payload: PublisherCampaignCreateRequest) =>
    this.instance.post<StandardCreateResponse>(
      `${this.basePath}/publishers/campaign`,
      payload
    );

  // getPublisherCampaignById will recive data from parameters and get a publisher campaign
  public getPublisherCampaignById = (id: number) =>
    this.instance.get<PublisherCampaign>(
      `${this.basePath}/publishers/campaign/${id}`
    );

  // editPublisherCampaign will recive data from parameters and edit a publisher campaign
  public editPublisherCampaign = (
    id: number,
    params: PublisherCampaignCreateRequest
  ) =>
    this.instance.put<StandardCreateResponse>(
      `${this.basePath}/publishers/campaign/${id}`,
      params
    );

  /* ------------- */

  // getPublisherCampaignBidList will recive data from parameters and get a publisher campaign bid
  public getPublisherCampaignBidList = (
    publisherId: number,
    publisherCampaignId: number,
    params: URLSearchParams
  ) =>
    this.instance.get<PublisherCampaignBid[]>(
      `${this.basePath}/publisher/${publisherId}/campaign/${publisherCampaignId}/bids`,
      { params: params }
    );

  /* ------------- */

  // getFeedFormatsList will recive data from parameters and get a list of feeds
  public getFeedFormatsList = (params: URLSearchParams) =>
    this.instance.get<FeedFormatResponse[]>(`${this.basePath}/feed-format`, {
      params: params,
    });

  /* ------------- */

  /**
   * getBidRanges requests a list of bidranges given the params
   *
   * @param params query params to send to endpoint
   * @returns promise with the http response.
   */
  public getBidRanges = (params: URLSearchParams) =>
    this.instance.get<BidRangesResponse>(`${this.basePath}/bid-range`, {
      params: params,
    });
}
