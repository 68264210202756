import { HttpClient } from './httpclient';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import { AxiosRequestConfig } from 'axios';
import { Business } from '@/models/business';

/**
 * BusinessesClient implements the methods to communicate with Businesses Service through
 * Management Backend service.
 */
export default class BusinessesClient extends HttpClient {
  private static classInstance?: BusinessesClient;

  basePath = '/api/v1/businesses';
  token = '';
  /**
   *
   * @param baseURL Base url for the client. Defaults to VUE_APP_BACKEND_BASE_URL env var.
   * @param token Authorization token to talk to adapult
   */
  private constructor(
    baseURL = process.env.VUE_APP_BACKEND_BASE_URL,
    token: string
  ) {
    super(baseURL);
    this.token = token;
    if (this.token != '') {
      this._initializeRequestInterceptor();
    }

    this._transformPayload();
  }

  /**
   *
   * @param baseURL Base url for the client. Defaults to VUE_APP_BACKEND_BASE_URL env var.
   * @param token Authorization token to talk to adapult
   * @returns
   */
  public static getInstance(
    baseURL = process.env.VUE_APP_BACKEND_BASE_URL,
    token: string
  ) {
    if (!this.classInstance) {
      this.classInstance = new BusinessesClient(baseURL, token);
    }
    return this.classInstance;
  }

  /**
   * Make all requests transform from:
   * snake_case response payload -> camelCase transformation
   * camelCase request payload -> snake_case transformation
   */
  private _transformPayload = () => {
    this.instance.defaults.transformResponse = [
      (data, headers) => {
        if (data && headers) {
          if (headers['content-type'].includes('application/json')) {
            return camelcaseKeys(JSON.parse(data), { deep: true });
          }
        }
      },
    ];

    this.instance.defaults.transformRequest = [
      (data, headers) => {
        if (data && headers) {
          if (headers['Content-Type'].includes('application/json')) {
            headers['Content-Type'] = 'application/json';
            return JSON.stringify(snakecaseKeys(data, { deep: true }));
          }
        }
      },
    ];
  };

  /**
   * Makes all requests add the authorization token in the request IF the object
   * was constructed with a non empty token, otherwise ignored.
   */
  private _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(
      this._handleRequest,
      this._handleError
    );
  };

  private _handleRequest = (config: AxiosRequestConfig) => {
    if (config.headers) {
      config.headers['Authorization'] = this.token;
    }

    return config;
  };

  /**
   * Makes a call to the backend to create a business
   * @param id The id of the business to edit
   * @param params data to send in request's body
   * @returns Promise to be handled
   */
  public editBusiness = (id: number, params: Business) =>
    this.instance.patch<Business>(`${this.basePath}/${id}/`, params);

  /**
   * Makes a call to the backend to retrieve a business by id
   * @param params
   * @returns Promise to be http response
   */
  public getBusinessById = (id: number) =>
    this.instance.get<Business>(`${this.basePath}/${id}/`);
}
