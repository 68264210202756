import { getField, updateField } from 'vuex-map-fields';
import AdapultClient from '@/services/http/adapultClient';
import { CONTENT_TYPE_OPTIONS, SOURCE_OPTIONS } from '@/constants/forms';
import { DEFAULT_STATUS } from '@/constants/configVariables';

const state = () => ({
  campaign: {
    id: 0,
    name: '',
    publisherId: 0,
    sourceId: 0,
    filename: '',
    contentTypeId: 0,
    // TODO: status and format should be handled on form
    status: 'active',
    feedFormatId: 0,
    path: '',
  },
  publishers: [
    {
      text: '',
      value: '',
    },
  ],
  feedFormats: [
    {
      text: '',
      value: '',
    },
  ],
  sources: SOURCE_OPTIONS,
  statusItem: DEFAULT_STATUS,
  contentTypes: CONTENT_TYPE_OPTIONS,
  validForm: {
    campaignValid: false,
    bidsValid: true,
    rulesValid: true,
  },
});

const getters = {
  getField,
  /**
   * isValid will return true if the 3 forms valid state are valid(true)
   *
   * @param {*} state reference to campaign state
   * @returns
   */
  isFormValid(state) {
    if (
      state.validForm.campaignValid === true &&
      state.validForm.bidsValid === true &&
      state.validForm.rulesValid === true
    ) {
      return true;
    }

    return false;
  },
};

const actions = {
  /**
   * getPublisherList obtains the list of Publishers from the backend and commits
   * the data retrieved and mapped to the desired shape to the publishers state.
   *
   * @param {*} state reference to the state of the module
   * @param {*} options query that we will send in the request
   */
  async getPublisherList(state, options) {
    try {
      const res = await AdapultClient.getInstance().getPublishers(options);
      const publishers = res.data.items.map((p) => {
        return {
          text: p.name,
          value: p.id,
        };
      });
      state.commit('setPublishers', publishers);
    } catch (error) {
      console.log(error);
    }
  },

  /**
   * getFeedFormatsList obtains the list of Publishers from the backend and commits
   * the data retrieved and mapped to the desired shape to the publishers state.
   *
   * @param {*} state reference to the state of the module
   * @param {*} options query that we will send in the request
   */
  async getFeedFormatsList(state, options) {
    try {
      const res = await AdapultClient.getInstance().getFeedFormatsList(options);
      const feedFormats = res.data.items.map((p) => {
        return {
          text: p.name,
          value: p.id,
        };
      });
      state.commit('setFeedFormats', feedFormats);
    } catch (error) {
      console.log(error);
    }
  },

  /**
   * getPublisherCampaignById will retrieve a single publisher campaign record given the id.
   * If the campaign has rules, it will commit them to the rules module.
   *
   * @param {*} state reference to the sate
   * @param {*} id publisher campaign id
   */
  async getPublisherCampaignById(state, id) {
    try {
      const res = await AdapultClient.getInstance().getPublisherCampaignById(
        id
      );
      state.commit('setCampaign', res.data);
      state.dispatch('publisherCampaignRulesForm/setRulesEmpty', '', {
        root: true,
      });
      if (res.data.rules !== undefined && res.data.rules !== null) {
        if (res.data.rules.length > 0)
          state.commit('publisherCampaignRulesForm/setRules', res.data.rules, {
            root: true,
          });
      }
    } catch (error) {
      console.log('error', error);
    }
  },

  setCampaignEmpty(state) {
    state.commit('setCampaign', {
      id: 0,
      name: '',
      publisherId: 0,
      sourceId: 0,
      filename: '',
      contentTypeId: 0,
      rules: [],
      // TODO: status and format should be handled on form
      status: 'active',
      feed_format_id: 1,
    });
  },
  /**
   * updateCampaign updates the state of the campaign state
   * to the new passed value.
   *
   * @param {*} state reference to the state of the module
   * @param {*} campaign values to set to the campaign state.
   */
  updateCampaign(state, campaign) {
    state.commit('setCampaign', campaign);
  },

  async createPublisherCampaign(state) {
    return new Promise((resolve, reject) => {
      let campaign = state.state.campaign;

      campaign.bids = state.rootState.publisherCampaignBidsForm.bids;
      campaign.bids.map((item) => {
        item.value = parseFloat(item.value);
      });

      const rules = state.rootState.publisherCampaignRulesForm.rules;
      if (rules.length > 0) {
        if (rules[0].value !== '') {
          campaign.rules = rules;
        }
      }

      AdapultClient.getInstance()
        .postPublisherCampaign(campaign)
        .then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  },
  /**
   * updatePublisherCampaign updates the state of the publisher campaign state
   * to the new passed value.
   *
   * @param {*} state reference to the state of the module
   */
  async updatePublisherCampaign(state) {
    // filling the payload
    let publisherCampaign = state.state.campaign;
    const rules = state.rootState.publisherCampaignRulesForm.rules;
    if (rules.length > 0) {
      if (rules[0].value !== '') {
        publisherCampaign.rules = rules;
      }
    }

    publisherCampaign.bids = state.rootState.publisherCampaignBidsForm.bids;
    // for to switch value string to int
    for (
      var i = 0;
      i < state.rootState.publisherCampaignBidsForm.bids.length;
      i++
    ) {
      publisherCampaign.bids[i].value = parseInt(
        publisherCampaign.bids[i].value
      );
    }
    publisherCampaign.bids.map((item) => {
      item.value = parseFloat(item.value);
    });

    try {
      //Calling function to update the publisher campaign
      await AdapultClient.getInstance().editPublisherCampaign(
        state.state.campaign.id,
        publisherCampaign
      );
    } catch (error) {
      // if an error occours print error
      console.log('error', error);
    }
  },
};

const mutations = {
  updateField,
  /**
   * setCampaign sets(replaces) the state of the campaign state object. Can be called
   * on initial load by an action.
   *
   * @param {*} state reference to the state of the module
   * @param {*} campaign values to set on campaign state.
   */
  setCampaign(state, campaign) {
    state.campaign = campaign;
  },
  /**
   * setPublishers sets(replaces) the state of the publishers object. Shoud be called
   * on initial load only.
   *
   * @param {*} state reference to the state of the module
   * @param {*} publishers values to set on publishers state.
   */
  setPublishers(state, publishers) {
    state.publishers = publishers;
  },

  /**
   * setPublishers sets(replaces) the state of the publishers object. Shoud be called
   * on initial load only.
   *
   * @param {*} state reference to the state of the module
   * @param {*} feedFormats values to set on publishers state.
   */
  setFeedFormats(state, feedFormats) {
    state.feedFormats = feedFormats;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
